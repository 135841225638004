// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/login';
const ROOTS_DASHBOARD = '/app';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: ROOTS_AUTH,
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/dashboard'),
    logout: '/logout'
  },
  members: {
    root: path(ROOTS_DASHBOARD, '/membros'),
    createMember: path(ROOTS_DASHBOARD, '/membros/cadastro'),
    updateMember: path(ROOTS_DASHBOARD, '/membros/editar/${id}')
  },
  persons: {
    root: path(ROOTS_DASHBOARD, '/pessoas'),
    createRegister: path(ROOTS_DASHBOARD, '/pessoas/cadastro'),
    updateRegister: path(ROOTS_DASHBOARD, '/pessoas/editar/${id}')
  },
  companies: {
    root: path(ROOTS_DASHBOARD, '/empresas'),
    createRegister: path(ROOTS_DASHBOARD, '/empresas/cadastro'),
    updateRegister: path(ROOTS_DASHBOARD, '/empresas/editar/${id}')
  },
  players: {
    root: path(ROOTS_DASHBOARD, '/jogadores'),
    createRegister: path(ROOTS_DASHBOARD, '/jogadores/cadastro'),
    updateRegister: path(ROOTS_DASHBOARD, '/jogadores/editar/${id}'),
  },
  platforms: {
    root: path(ROOTS_DASHBOARD, '/plataformas'),
    createRegister: path(ROOTS_DASHBOARD, '/plataformas/cadastro'),
    updateRegister: path(ROOTS_DASHBOARD, '/plataformas/editar/${id}'),
  },
  leagues: {
    root: path(ROOTS_DASHBOARD, '/ligas'),
    createRegister: path(ROOTS_DASHBOARD, '/ligas/cadastro'),
    updateRegister: path(ROOTS_DASHBOARD, '/ligas/editar/${id}'),
  },
  slots: {
    root: path(ROOTS_DASHBOARD, '/slots'),
    createRegister: path(ROOTS_DASHBOARD, '/slots/cadastro'),
    updateRegister: path(ROOTS_DASHBOARD, '/slots/editar/${id}'),
  },
  instructor: {
    root: path(ROOTS_DASHBOARD, '/instrutores'),
    createRegister: path(ROOTS_DASHBOARD, '/instrutores/cadastro'),
    updateRegister: path(ROOTS_DASHBOARD, '/instrutores/editar/${id}')
  },
  tournament: {
    root: path(ROOTS_DASHBOARD, '/torneios'),
    createRegister: path(ROOTS_DASHBOARD, '/torneios/cadastro'),
    updateRegister: path(ROOTS_DASHBOARD, '/torneios/editar/${id}'),
    teamLineup: path(ROOTS_DASHBOARD, '/torneios/${id}/escalacao')
  },
  call: {
    root: path(ROOTS_DASHBOARD, '/lista-de-chamadas')
  },
  types: {
    root: path(ROOTS_DASHBOARD, '/tipos'),
    createRegister: path(ROOTS_DASHBOARD, '/tipos/cadastro'),
    updateRegister: path(ROOTS_DASHBOARD, '/tipos/editar/${id}')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/usuarios'),
    account: path(ROOTS_DASHBOARD, '/usuarios/conta'),
    edit: path(ROOTS_DASHBOARD, '/usuarios/conta/${id}')
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/configuracoes'),
  },
  // settings: {
  //   root: path(ROOTS_DASHBOARD, '/configuracoes'),
  //   team: path(ROOTS_DASHBOARD, '/configuracoes/times'),
  //   createTeam: path(ROOTS_DASHBOARD, '/configuracoes/times/cadastro'),
  //   editTeam: path(ROOTS_DASHBOARD, '/configuracoes/times/editar/${id}'),
  //   subteam: path(ROOTS_DASHBOARD, '/configuracoes/subtimes'),
  //   createSubteam: path(ROOTS_DASHBOARD, '/configuracoes/subtimes/cadastro'),
  //   editSubteam: path(ROOTS_DASHBOARD, '/configuracoes/subtimes/editar/${id}')
  // },
  worksheet: {
    root: path(ROOTS_DASHBOARD, '/planilhas'),
    importData: path(ROOTS_DASHBOARD, '/planilhas/importar-dados')
  },
  query: {
    root: path(ROOTS_DASHBOARD, '/consultas'),
    trades: path(ROOTS_DASHBOARD, '/consultas/transacoes'),
    matches: path(ROOTS_DASHBOARD, '/consultas/partidas'),
  },
  report: {
    root: path(ROOTS_DASHBOARD, '/relatorios'),
    // players: path(ROOTS_DASHBOARD, '/relatorios/jogadores')
    closure: path(ROOTS_DASHBOARD, '/relatorios/fechamento'),
  },
  financial: {
    root: path(ROOTS_DASHBOARD, '/financeiro'),
    releases: path(ROOTS_DASHBOARD, '/financeiro/lancamentos'),
    releasesCreate: path(ROOTS_DASHBOARD, '/financeiro/lancamentos/cadastro'),
    releasesUpdate: path(ROOTS_DASHBOARD, '/financeiro/lancamentos/editar/${id}'),
    extract: path(ROOTS_DASHBOARD, '/financeiro/extrato')
  },
  ringGames: {
    root: path(ROOTS_DASHBOARD, '/ring-games'),
    createRegister: path(ROOTS_DASHBOARD, '/ring-games/cadastro'),
    updateRegister: path(ROOTS_DASHBOARD, '/ring-games/editar/${id}'),
    teamLineup: path(ROOTS_DASHBOARD, '/ring-games/${id}/escalacao')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
