// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  settings: getIcon('settings'),
  article: getIcon('article'),
  groups: getIcon('groups'),
  description: getIcon('description'),
  account_balance: getIcon('account_balance')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'inicio',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'usuários',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user
      },
      {
        title: 'cadastros',
        path: 'cadastros',
        icon: ICONS.groups,
        children: [
          // {title: 'Membros', path: PATH_DASHBOARD.members.root},
          { title: 'Pessoas', path: PATH_DASHBOARD.persons.root },
          { title: 'Empresas', path: PATH_DASHBOARD.companies.root },
          { title: 'Jogadores', path: PATH_DASHBOARD.players.root },
          { title: 'Instrutores', path: PATH_DASHBOARD.instructor.root },
          { title: 'Plataformas', path: PATH_DASHBOARD.platforms.root },
          { title: 'Ligas', path: PATH_DASHBOARD.leagues.root },
          { title: 'Slots', path: PATH_DASHBOARD.slots.root },
          // {title: 'Tipos', path: PATH_DASHBOARD.types.root},
          // { title: 'Times', path: PATH_DASHBOARD.settings.team },
          // { title: 'Sub-celula', path: PATH_DASHBOARD.settings.subteam }
        ]
      },
      {
        title: 'Configurações',
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.settings
      },
      {
        title: 'Games',
        path: PATH_DASHBOARD.ringGames.root,
        icon: ICONS.groups,
        children: [
          { title: 'Torneios', path: PATH_DASHBOARD.tournament.root },
          { title: 'Lista de chamadas', path: PATH_DASHBOARD.call.root },
          // { title: 'Ring Games', path: PATH_DASHBOARD.ringGames.root },
        ]
      },
      {
        title: 'Financeiro',
        path: PATH_DASHBOARD.financial.root,
        icon: ICONS.account_balance,
        children: [
          { title: 'Lançamentos', path: PATH_DASHBOARD.financial.releases },
          // { title: 'Extrato', path: PATH_DASHBOARD.financial.extract },
        ]
      },
      // {
      //   title: 'consultas',
      //   path: PATH_DASHBOARD.query.root,
      //   icon: ICONS.description,
      //   children: [
      //     { title: 'Transações', path: PATH_DASHBOARD.query.trades },
      //     { title: 'Partidas', path: PATH_DASHBOARD.query.matches }
      //   ]
      // },
      {
        title: 'relatórios',
        path: PATH_DASHBOARD.report.root,
        icon: ICONS.description,
        children: [
          // { title: 'Jogadores', path: PATH_DASHBOARD.report.players },
          { title: 'Fechamento', path: PATH_DASHBOARD.report.closure },
        ]
      },
      // {
      //   title: 'planilhas',
      //   path: PATH_DASHBOARD.worksheet.root,
      //   icon: ICONS.article
      // },
    ]
  },
];

export default sidebarConfig;
