import axios from 'axios';
import * as auth from '../auth/auth';
// import { CONFIG } from 'constants/constants';
let interceptorAuth = null;

// export const URL_API = CONFIG.api;
export const URL_API = process.env.NODE_ENV === "development" ? 'http://127.0.0.1:3003' : 'https://api.time.dratecnologia.com.br';

const API = axios.create({
    baseURL: URL_API
});

export const setInterceptorAuth = () => {
    interceptorAuth = API.interceptors.request.use((config) => {
        const token = auth.getToken();
        config.headers.Authorization = `Bearer ${token ? token : ''}`;
        return config;
    });
}

export const removeInterceptorAuth = () => {
    API.interceptors.request.eject(interceptorAuth);
}

if (auth.isAuthenticated())
    setInterceptorAuth();

export default API;