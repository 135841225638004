import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: 'app',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/app/dashboard" replace /> },
        { path: 'dashboard', element: <GeneralApp /> },
        {
          path: 'usuarios', children: [
            { element: <UserList /> },
            { path: 'conta', element: <UserCreate /> },
            { path: 'conta/:id', element: <UserCreate /> }
          ]
        },
        {
          path: 'configuracoes', children: [
            { element: <Settings /> },
          ]
        },
        {
          path: 'membros', children: [
            { element: <MembersList /> },
            { path: 'cadastro', element: <MemberCreate /> },
            { path: 'editar/:id', element: <MemberCreate /> }
          ]
        },
        {
          path: 'pessoas',
          children: [
            { element: <PersonsList /> },
            { path: 'cadastro', element: <PersonCreate /> },
            { path: 'editar/:id', element: <PersonCreate /> }
          ]
        },
        {
          path: 'empresas',
          children: [
            { element: <CompanyList /> },
            { path: 'cadastro', element: <CompanyCreate /> },
            { path: 'editar/:id', element: <CompanyCreate /> }
          ]
        },
        {
          path: 'jogadores',
          children: [
            { element: <PlayersList /> },
            { path: 'cadastro', element: <PlayersCreate /> },
            { path: 'editar/:id', element: <PlayersCreate /> },
          ]
        },
        {
          path: 'instrutores',
          children: [
            { element: <InstructorList /> },
            { path: 'cadastro', element: <IntructorCreate /> },
            { path: 'editar/:id', element: <IntructorCreate /> }
          ]
        },
        {
          path: 'plataformas',
          children: [
            { element: <PlatformsList /> },
            { path: 'cadastro', element: <PlatformCreate /> },
            { path: 'editar/:id', element: <PlatformCreate /> }
          ]
        },
        {
          path: 'ligas',
          children: [
            { element: <LeaguesList /> },
            { path: 'cadastro', element: <LeaguesCreate /> },
            { path: 'editar/:id', element: <LeaguesCreate /> }
          ]
        },
        {
          path: 'slots',
          children: [
            { element: <SlotsList /> },
            { path: 'cadastro', element: <SlotsCreate /> },
            { path: 'editar/:id', element: <SlotsCreate /> }
          ]
        },
        {
          path: 'tipos',
          children: [
            { element: <TypesList /> },
            { path: 'cadastro', element: <TypesCreate /> },
            { path: 'editar/:id', element: <TypesCreate /> },
          ]
        },
        {
          path: 'financeiro',
          children: [
            {
              path: 'lancamentos', children: [
                { element: <FinanceReleasesList /> },
                { path: 'cadastro', element: <ReleasesCreate /> },
                { path: 'editar/:id', element: <ReleasesCreate /> },
              ]
            },
            { path: 'extrato', element: <FinanceExtract /> }
          ]
        },
        {
          path: 'torneios',
          children: [
            { element: <TournamentList /> },
            { path: 'cadastro', element: <TournamentCreate /> },
            { path: 'editar/:id', element: <TournamentCreate /> },
            { path: ':id/escalacao', element: <TeamLineupCreate /> }
          ]
        },
        {
          path: 'lista-de-chamadas',
          element: <CallList />
        },
        {
          path: 'ring-games',
          children: [
            { element: <RingGames /> },
            { path: 'cadastro', element: <RingGamesCreate /> },
            { path: 'editar/:id', element: <RingGamesCreate /> },
            { path: ':id/escalacao', element: <TeamLineupCreate /> }
          ]
        },
        {
          path: 'configuracoes',
          children: [
            {
              path: 'times', children: [
                { element: <TeamsList /> },
                { path: 'cadastro', element: <TeamCreate /> },
                { path: 'editar/:id', element: <TeamCreate /> }
              ]
            },
            {
              path: 'subtimes', children: [
                { element: <SubteamList /> },
                { path: 'cadastro', element: <SubteamCreate /> },
                { path: 'editar/:id', element: <SubteamCreate /> }
              ]
            }
          ]
        },
        {
          path: 'relatorios',
          children: [
            { path: 'jogadores', element: <ReportPlayersList /> },
            { path: 'fechamento', element: <ReportClosure /> }
          ]
        },
        {
          path: 'planilhas', children: [
            { element: <WorksheetList /> },
            { path: 'importar-dados', element: <ImportData /> }
          ]
        },
        {
          path: 'consultas', children: [
            { path: 'transacoes', element: <TradesList /> },
            { path: 'partidas', element: <MatchesList /> }
          ]
        }
      ]
    },
    {
      path: 'login',
      children: [
        { element: <Login /> },
        { path: 'verificar-codigo', element: <VerifyCode /> }
      ]
    },
    {
      path: 'definir-senha/:hash', element: <SetPassword />
    },
    {
      path: 'logout',
      element: <Logout />
    },
    {
      path: '/', element: <Navigate to="/login" />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const Logout = Loadable(lazy(() => import('../pages/dashboard/Logout')));
// Main
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

const TeamsList = Loadable(lazy(() => import('../pages/dashboard/TeamsList')));
const TeamCreate = Loadable(lazy(() => import('../pages/dashboard/TeamCreate')));

const SubteamList = Loadable(lazy(() => import('../pages/dashboard/SubteamsList')));
const SubteamCreate = Loadable(lazy(() => import('../pages/dashboard/SubteamsCreate')));

const MembersList = Loadable(lazy(() => import('../pages/dashboard/MembersList')));
const MemberCreate = Loadable(lazy(() => import('../pages/dashboard/MemberCreate')));

const WorksheetList = Loadable(lazy(() => import('../pages/dashboard/WorksheetList')));

const ImportData = Loadable(lazy(() => import('../pages/dashboard/ImportData')));
const TradesList = Loadable(lazy(() => import('../pages/dashboard/TradesList')));
const MatchesList = Loadable(lazy(() => import('../pages/dashboard/MatchesList')));

const SetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));

const PersonsList = Loadable(lazy(() => import('../pages/dashboard/PersonList')));
const PersonCreate = Loadable(lazy(() => import('../pages/dashboard/PersonCreate')));

const CompanyList = Loadable(lazy(() => import('../pages/dashboard/CompanyList')));
const CompanyCreate = Loadable(lazy(() => import('../pages/dashboard/CompanyCreate')));

const PlayersList = Loadable(lazy(() => import('../pages/dashboard/PlayersList')));
const PlayersCreate = Loadable(lazy(() => import('../pages/dashboard/PlayersCreate')));

const InstructorList = Loadable(lazy(() => import('../pages/dashboard/InstructorList')));
const IntructorCreate = Loadable(lazy(() => import('../pages/dashboard/InstructorCreate')));

const PlatformsList = Loadable(lazy(() => import('../pages/dashboard/PlatformsList')));
const PlatformCreate = Loadable(lazy(() => import('../pages/dashboard/PlatformCreate')));

const TournamentList = Loadable(lazy(() => import('../pages/dashboard/TournamentList')));
const TournamentCreate = Loadable(lazy(() => import('../pages/dashboard/TournamentCreate')));
const TeamLineupCreate = Loadable(lazy(() => import('../pages/dashboard/TeamLineupCreate')));

const TypesList = Loadable(lazy(() => import('../pages/dashboard/TypesList')));
const TypesCreate = Loadable(lazy(() => import('../pages/dashboard/TypesCreate')));

const ReportPlayersList = Loadable(lazy(() => import('../pages/dashboard/ReportPlayersList')));
const FinanceReleasesList = Loadable(lazy(() => import('../pages/dashboard/FinanceReleasestList')));
const ReleasesCreate = Loadable(lazy(() => import('../pages/dashboard/ReleasesCreate')));
const FinanceExtract = Loadable(lazy(() => import('../pages/dashboard/FinanceExtract')));

const LeaguesList = Loadable(lazy(() => import('../pages/dashboard/LeaguesList')));
const LeaguesCreate = Loadable(lazy(() => import('../pages/dashboard/LeagueCreate')));

const SlotsList = Loadable(lazy(() => import('../pages/dashboard/SlotsList')));
const SlotsCreate = Loadable(lazy(() => import('../pages/dashboard/SlotsCreate')));

const Settings = Loadable(lazy(() => import('../pages/dashboard/Settings')));

const CallList = Loadable(lazy(() => import('../pages/dashboard/CallList')));

const ReportClosure = Loadable(lazy(() => import('../pages/dashboard/ReportClosure')));

const RingGames = Loadable(lazy(() => import('../pages/dashboard/RingGamesList')));
const RingGamesCreate = Loadable(lazy(() => import('../pages/dashboard/RingGamesCreate')));

