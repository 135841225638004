import Cookie from 'universal-cookie';

export const cookies = new Cookie();

export const isAuthenticated = () => {
//    const token = localStorage.getItem('token');
    const auth = cookies.get('auth');  
   
    if (!auth) {
        localStorage.setItem('notification', 
        JSON.stringify({error: true, message: 'Usuário não autenticado!'}));
        
        return false;
    }
    return true;
};

export const getToken = () => {
    const auth = cookies.get('auth');  
    return auth.token;
}

export const getLevel = () => {
    const auth = cookies.get('auth');  
    return auth ? auth.typeAccount : null;
}

export const getUser = () => {
    const auth = cookies.get('auth');  
    return auth ? auth.user : {};
}

export const clearCookie = () => {
    cookies.remove('auth');
}

export default {
    cookies,
    clearCookie,
    isAuthenticated,
    getToken,
    getLevel,
    getUser
}